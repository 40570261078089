import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { BodyText2SemiBold } from '@lumoslabs/lumosity-storybook'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { loginValidateApi } from '~/apis/loginPasswordValidate'
import { FormContainer, VerticalInputs } from '~/components/auth/FormStyledComponents'
import { SocialAuthForm } from '~/components/auth/SocialAuthForm'
import { ValidatableInput } from '~/components/auth/ValidatableInput'
import ButtonWithTracking from '~/components/ui/ButtonWithTracking'
import SkeletonLoader from '~/components/ui/SkeletonLoader'
import { ErrorEventTypes } from '~/events/eventTypes'
import useTrackClick from '~/events/trackers/useTrackClick'
import { usePostLogin } from '~/hooks/useAuth'
import { useErrorNotification } from '~/hooks/useErrorNotification'
import { OryAuthParams, useOryFlows } from '~/hooks/useOryFlows'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import IconAlert from '~/images/icons/SystemOutlined/Alert.svg'
import IconHide from '~/images/icons/SystemOutlined/Hide.svg'
import IconShow from '~/images/icons/SystemOutlined/Show.svg'
import logger from '~/utils/logger'
import { validateChain, ValidationResult } from '~/utils/validationUtils'

import { getOryFrontendClient } from '../../clients/oryClient'

const flowErrorType = ErrorEventTypes.SignInFail

// accountLinkAuthParams - is mapped response from oryFrontendClient.getLoginFlow({ id: flowId})
// in case of merge account, only show relevant  auth providers
// if its not a merge account, show all auth providers
export const SignInFormComponent: React.FC<{ accountLinkAuthParams?: OryAuthParams | undefined }> = ({
  accountLinkAuthParams,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { processOrySession, createOryReturnToUrl } = usePostLogin()

  const showPasswordFlow = accountLinkAuthParams ? accountLinkAuthParams?.showPassword : true

  const [email, setEmail] = useState<string>(accountLinkAuthParams?.emailValue || '')
  const [shouldValidateEmail, setShouldValidateEmail] = useState<boolean>(false)

  const [password, setPassword] = useState<string>('')
  const [shouldValidatePassword, setShouldValidatePassword] = useState<boolean>(false)
  const [maskPassword, setMaskPassword] = useState<boolean>(true)
  const { trackCta } = useTrackClick()

  const { processOryFlowResponse, processOryApiError } = useOryFlows()

  const t = useTranslationForNamespace('signin')

  const validateEmail = useCallback((): ValidationResult => {
    if (email.length === 0) {
      return {
        isValid: false,
        errorMessage: t('emailRequired'),
      }
    }
    if (
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
      return {
        isValid: false,
        errorMessage: t('emailInvalid'),
      }
    }
    return {
      isValid: true,
      errorMessage: undefined,
    }
  }, [email, t])

  const validatePassword = useCallback((): ValidationResult => {
    if (password.length === 0) {
      return {
        isValid: false,
        errorMessage: t('passwordRequired'),
      }
    }
    if (password.length < 6) {
      return {
        isValid: false,
        errorMessage: t('passwordTooShort'),
      }
    }
    return {
      isValid: true,
      errorMessage: undefined,
    }
  }, [password, t])

  const toggleMaskPassword = () => {
    setMaskPassword(!maskPassword)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleEmailBlur = () => {
    setShouldValidateEmail(true)
  }

  const handleEmailFocus = () => {
    setShouldValidateEmail(false)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handlePasswordBlur = () => {
    setShouldValidatePassword(true)
  }

  const handlePasswordFocus = () => {
    setShouldValidatePassword(false)
  }

  const isFormValid = () => {
    return validateChain([validateEmail, validatePassword]).isValid
  }

  const { showErrorNotification } = useErrorNotification()

  const handleFormSubmit = async () => {
    try {
      if (isLoading) return
      setIsLoading(true)

      trackCta({
        text: t('login'),
        type: 'button',
        destination: 'home',
        click_name: 'login_email_password',
      })

      const loginValidateResp = await loginValidateApi({ email, password })
      if (loginValidateResp.error) {
        if (loginValidateResp.error.code === 'CREDENTIALS_MISMATCH') {
          showErrorNotification('common.authErrors.invalidLoginCredential')
        } else {
          showErrorNotification('common.authErrors.defaultError')
        }
        setIsLoading(false)
        return
      }

      const oryFrontendClient = getOryFrontendClient()

      const response = accountLinkAuthParams
        ? await oryFrontendClient.getLoginFlow({ id: accountLinkAuthParams.flowId })
        : await oryFrontendClient.createBrowserLoginFlow({ returnTo: createOryReturnToUrl() })
      const loginFlow = response.data
      const { hasError, authParams } = processOryFlowResponse(loginFlow, { flowErrorType })
      if (hasError) {
        setIsLoading(false)
        return
      }
      const { csrfToken, flowId } = authParams

      const updateResponse = await oryFrontendClient.updateLoginFlow({
        flow: flowId,
        updateLoginFlowBody: {
          csrf_token: csrfToken,
          method: 'password',
          password,
          identifier: email,
        },
      })
      const { session } = updateResponse.data
      if (!session) {
        showErrorNotification('Could not retrieve the session for the user')
        setIsLoading(false)
        return
      }

      await processOrySession({ orySession: session, onError: showErrorNotification })
    } catch (error) {
      await processOryApiError(error, { flowErrorType }) // processOryApiError is wrapped under try catch, thus it will not fail
      logger.error('Error::SignInForm::handleFormSubmit', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onAccountRecoveryClick = () => {
    trackCta({
      text: t('forgotPassword'),
      type: 'link',
      destination: 'forgot_password',
      click_name: 'login_forgot_password',
    })
  }

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <>
      {accountLinkAuthParams && <InstructionMergeAccount>{t('accountLinkInfo')}</InstructionMergeAccount>}
      {showPasswordFlow ? (
        <>
          <FormContainer>
            <VerticalInputs>
              <ValidatableInput
                hasValidationError={shouldValidateEmail && !validateEmail().isValid}
                value={email}
                id='email'
                type='email'
                labelString={t('email')}
                Icon={IconAlert}
                validationErrorMessage={validateEmail().errorMessage}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                onFocus={handleEmailFocus}
              />
              <ValidatableInput
                hasValidationError={shouldValidatePassword && !validatePassword().isValid}
                value={password}
                id='password'
                type={!maskPassword ? 'text' : 'password'}
                labelString={t('password')}
                Icon={!maskPassword ? IconShow : IconHide}
                onValidationIconClick={toggleMaskPassword}
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
                onFocus={handlePasswordFocus}
                alwaysShowIcon={true}
                validationErrorMessage={validatePassword().errorMessage}
              />
            </VerticalInputs>
          </FormContainer>
          <LoginButton kind='primary' disabled={!isFormValid()} onClick={handleFormSubmit}>
            {t('login')}
          </LoginButton>
        </>
      ) : null}
      {!accountLinkAuthParams && (
        <ForgotPasswordLink>
          <Link href={'/account-recovery'} onClick={onAccountRecoveryClick}>
            {t('forgotPassword')}
          </Link>
        </ForgotPasswordLink>
      )}
      <StyledSocialAuthForm>
        <SocialAuthForm screenName='LOGIN' accountLinkAuthParams={accountLinkAuthParams} />
      </StyledSocialAuthForm>
    </>
  )
}

export const SignInForm: React.FC = () => {
  const router = useRouter()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { processOryFlowResponse, processOryApiError } = useOryFlows()

  const accLinkingFlowId = useMemo(() => router.query.flow as string, [router.query.flow])
  const accountLinkFlowProcessed = useRef<boolean>(false)
  // accountLinkFlow is response from oryFrontendClient.getLoginFlow({ id: flowId})
  const [accountLinkAuthParams, setAccountLinkAuthParams] = useState<OryAuthParams | undefined>(undefined)

  useEffect(() => {
    if (!accLinkingFlowId) return
    if (accountLinkFlowProcessed.current) return

    /**
     * LUM-1795 - User register using password flow, and now trying to register/login in using social auth
     * kratos doesn't auto link the accounts, as social is a redirection flow, kratos lands user to login page
     * url might look like /login?flow=74fc44fb-4bb2-4a0d-9f0e-e1225a1a6146&no_org_ui=true
     * detecting this 'flow' and getting details using oryFrontendClient.getLoginFlow to show correct error message to user
     *
     * in order to link the account, we must render page based on oryFrontendClient.getLoginFlow  not from oryFrontendClient.createBrowserLoginFlow
     */
    async function kratosAccountLinkingFlow() {
      accountLinkFlowProcessed.current = true
      try {
        setIsLoading(true)
        const oryFrontendClient = getOryFrontendClient()
        const response = await oryFrontendClient.getLoginFlow({ id: accLinkingFlowId })
        const loginFlow = response.data
        const { hasError, authParams } = processOryFlowResponse(loginFlow, { flowErrorType })
        if (hasError) {
          setIsLoading(false)
          return
        }
        setAccountLinkAuthParams(authParams)
      } catch (error) {
        logger.error('Error::SignInForm::kratosAccountLinkingFlow', error)
        setAccountLinkAuthParams(undefined)
        await processOryApiError(error, { flowErrorType })
      } finally {
        setIsLoading(false)
      }
    }

    kratosAccountLinkingFlow()
  }, [router.query, processOryApiError, processOryFlowResponse, accLinkingFlowId])

  if (isLoading) {
    return <Skeleton />
  }

  return <SignInFormComponent accountLinkAuthParams={accountLinkAuthParams} />
}

const Skeleton: React.FC = () => {
  return (
    <SkeletonBarContainer>
      {Array.from({ length: 5 }).map((_, index) => (
        <SkeletonBar key={index} />
      ))}
    </SkeletonBarContainer>
  )
}

const SkeletonBarContainer = styled.div`
  width: 75%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`

const SkeletonBar = styled(SkeletonLoader)`
  width: 100%;
  height: 56px;
`

const LoginButton = styled(ButtonWithTracking)`
  margin-top: 28px;
  width: 252px;
`
const StyledSocialAuthForm = styled.div`
  margin-top: 40px;
`

const ForgotPasswordLink = styled.div`
  margin-top: 24px;
`
const InstructionMergeAccount = styled(BodyText2SemiBold)`
  margin-top: 20px;
  margin-bottom: 32px;
  text-align: center;
  display: flex;
  text-align: center;
  max-width: 400px;
`
