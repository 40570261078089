export type ValidationResult =
  | {
      isValid: true
      errorMessage: undefined
    }
  | {
      isValid: false
      errorMessage: string
    }
export const validateChain = (fns: (() => ValidationResult)[]): ValidationResult => {
  for (const fn of fns) {
    const result = fn()
    if (!result.isValid) {
      return {
        isValid: false,
        errorMessage: result.errorMessage,
      }
    }
  }
  return {
    isValid: true,
    errorMessage: undefined,
  }
}
