import axios, { AxiosError } from 'axios'
import getConfig from 'next/config'

import logger from '~/utils/logger'

const { publicRuntimeConfig } = getConfig()
const LoginValidateApiUrl = `${publicRuntimeConfig.authMiddleware.endpoint}/login/password/validate`

interface loginValidateResponse {
  data: any
  error: null | { code: 'UNKNOWN' | 'CREDENTIALS_MISMATCH' }
}

interface loginValidatePayload {
  email: string
  password: string
}

export const loginValidateApi = async (payload: loginValidatePayload): Promise<loginValidateResponse> => {
  try {
    const response = await axios.post(LoginValidateApiUrl, payload)
    logger.debug('loginValidate::errorJsonResp', response.status, response.data)
    return { data: response.data, error: null }
  } catch (err) {
    const errorResp = err as AxiosError<any>
    if (errorResp.response?.data?.errorCode !== 'CREDENTIALS_MISMATCH') {
      // CREDENTIALS_MISMATCH error, it's not an error
      logger.error('loginValidate:catchError', err)
    }
    return { data: null, error: { code: errorResp.response?.data?.errorCode || 'UNKNOWN' } }
  }
}
