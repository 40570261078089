
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React, { useMemo } from 'react'

import { BadgeIcon, Button, Caption1, Heading2 } from '@lumoslabs/lumosity-storybook'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { AlternateAuthFlowRow } from '~/components/auth/AlternateAuthFlowRow'
import { AuthFooter } from '~/components/auth/AuthFooter'
import { SignInForm } from '~/components/auth/SignInForm'
import { AuthLayout } from '~/components/layout/AuthLayout'
import useTrackScreenView from '~/events/trackers/useTrackScreenView'
import { useHomePageRedirect } from '~/hooks/useHomePageRedirect'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import ChevronLeftIcon from '~/images/icons/SystemOutlined/ChevronLeft.svg'

const LoginPage: NextPage = () => {
  useHomePageRedirect()
  const t = useTranslationForNamespace('signin')
  useTrackScreenView({ screen_name: 'login' })
  const router = useRouter()
  // This is used to determine if the user is in the account linking flow
  const accLinkingFlowId = useMemo(() => router.query.flow as string, [router.query.flow])

  const onBack = () => router.push('/signup')

  return (
    <AuthLayout
      leftColumnBackgroundImageUrl='/assets/images/auth/auth-bg.svg'
      headTitle={t('title')}
      leftColumnTitle={t('header')}
      leftColumnBackgroundColor={'#b4e6ef'}
      mobileBackgroundLinearGradient='linear-gradient(to right bottom, #ffffff 20%, #c7edf2 60%, #76d3ff 100%)'
    >
      {accLinkingFlowId && (
        <StyledBackButton
          onClick={onBack}
          leftBadge={
            <BadgeIcon>
              <ChevronLeftIcon title='Back' fontSize={'32px'} width={'32px'} height={'32px'} />
            </BadgeIcon>
          }
          kind='clean'
        >
          <Caption1>{t('common:buttons.back')}</Caption1>
        </StyledBackButton>
      )}
      {accLinkingFlowId ? <Heading2>{t('accountMergeTitle')}</Heading2> : <Heading2>{t('login')}</Heading2>}
      {!accLinkingFlowId && (
        <AlternateAuthFlowRow buttonLabel={t('createAccount')} buttonHref={'/signup'} prompt={t('noAccount')} />
      )}
      <SignInForm />
      <StyledAuthFooter legalAgreementTranslationKey={'common:signin.legalAgreement'} />
    </AuthLayout>
  )
}

export default LoginPage

const StyledAuthFooter = styled(AuthFooter)`
  margin-top: 28px;
`

const StyledBackButton = styled(Button)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
  padding: 0;
  span {
    font-weight: 600;
  }
`


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  